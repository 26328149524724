/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

* {
  box-sizing: border-box;
}

body,
h1,
h2 {
  margin: 0;
  padding: 0;
}

body {
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
}

h2 {
  color: #21243d;
  font-family: Hind, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Header */
.ais-HitsPerPage-option {
  /*box-sizing: border-box;*/
  font-size: 0.9rem;
}

.header {
  align-items: center;
  background-image: url('./assets/Untitled.png'),
  linear-gradient(to bottom, #b6ab85, #575247);

  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 368px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.header-logo {
  margin: 0;
}

.header-logo svg {
  height: 24px;
  width: 92px;
}

.header-title {
  font-size: 38px;
  font-weight: 300;
}

.header-subtitle {
  font-size: 18px;
  font-weight: 300;
  margin: 0.5rem 0 1rem;
}

.profile {
  display: flex;
  position: fixed;
  right: 0;
  margin: 1rem;

}

.profile:hover {
  cursor: pointer;
}

/* Containers */

.container {
  /*display: flex;*/
  margin: 0 auto;
  max-width: 80%;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;

  flex-direction: column;
}

.container-header {
  align-items: center;
  display: flex;
  justify-content: space-around;
  min-height: 80px;
  min-width: 20rem;

}


.container-results {
  flex: 3;
  max-width: 1360px;
  align-self: center;
}

.container-option-text {
  color: #21243d;
  font-size: 0.75rem;
  padding-right: 4px;
}

.container-options {
  border-bottom: 1px solid #ebecf3;
  display: flex;
  margin-bottom: 30px;
  padding: 30px 0;
}

.container-options .container-option:not(:first-child) {
  margin-left: 48px;
}

.container-options select {
  min-width: 80px;
}

em {
  font-style: normal;
}

em,
mark {
  background: rgba(226, 164, 0, 0.4);
}

/* Clear refinements container */

.clear-filters {
  align-items: center;
  display: flex;
}

.clear-filters svg {
  margin-right: 8px;
}

/* Panel */

.container-body .ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.ais-Panel-header {
  font-family: Hind, sans-serif;
}

/* Search box */

.header .ais-SearchBox {
  height: 64px;
  width: 740px;
}

.header .ais-SearchBox .ais-SearchBox-input {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.2);
  font-family: Hind, sans-serif;
  height: 64px;
  /*
    The "Hind" font family is vertically off-balance.
    Adding 4px of padding top makes it more vertically aligned.
  */
  padding: 4px 48px 0 64px;
}

.header .ais-SearchBox-submit {
  padding: 0 1rem 0 2rem;
  width: 64px;
}

.header .ais-SearchBox .ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.24);
  opacity: 1; /* Firefox */
}

.search {
  /*color: rgba(33, 36, 61, 0.24);*/
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 4px 48px 0 rgb(0 0 0 / 20%);
  /*margin: auto;*/
  /*height: 64px;*/
  width: 740px;
}

@media (max-width: 800px) {
  .search {
    max-width: 380px;
  }

}


.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-submit {
  color: #e2a400;
}

.ais-RefinementList .ais-SearchBox-input {
  font-family: Hind, sans-serif;
  /*
    The "Hind" font family is vertically off-balance.
    Adding some padding top makes it more vertically aligned.
  */
  padding-top: 2px;
}

/* Hits */

.hit {
  color: #21243d;
  font-size: 14px;
  line-height: 18px;
}

.hit:hover {
  cursor: pointer;
}

.hit h1 {
  font-size: 14px;
}

.hit-category {
  color: #21243d;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  opacity: 0.7;
  text-transform: uppercase;
}

.hit-description {
  margin-top: 12px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ais-Snippet {
  color: #21243d;
  font-size: 14px;
  line-height: 18px;
}

.ais-Snippet-nonHighlighted {
  margin-top: 2px;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
}

.hit-info-container {
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hit-image-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto auto 10px;
}

.hit-image {
  height: auto;
  max-height: 180px;
  max-width: 320px;
}

/* media for hit image*/
@media (max-width: 1000px) {
  .hit-image {
    max-height: 100px;
    max-width: 180px;
  }

}

.hit-em {
  color: #e2a400;
  font-size: 11px;
  font-weight: 600;
}

.hit-rating {
  border: 1px solid rgba(226, 164, 0, 0.5);
  border-radius: 4px;
  margin-left: 4px;
  padding: 0 4px;
}

.hits-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 300px;
}

.hits-empty-state-title {
  font-family: Hind;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.hits-empty-state-description {
  color: rgba(35, 37, 51, 0.6);
  font-size: 0.875rem;
  text-align: center;
}

.hits-empty-state .ais-ClearRefinements {
  margin-top: 1rem;
}

.hits-empty-state .ais-ClearRefinements-button--disabled {
  display: none;
}

.hits-empty-state .ais-ClearRefinements-button {
  background: rgba(10, 8, 41, 0.04);
  border-radius: 3px;
  color: #21243d;
  min-height: 48px;
  padding: 16px 24px;
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ais-ToggleRefinement-checkbox {
  font: inherit;
  margin-left: 1rem;
  margin-right: 0;
  position: relative;
}

.ais-ToggleRefinement-checkbox:checked::before {
  color: #e2a400;
}

.ais-ToggleRefinement-checkbox::before {
  align-items: center;
  color: rgba(33, 36, 61, 0.32);
  content: 'No';
  display: flex;
  font-size: 0.8rem;
  height: 16px;
  position: absolute;
  right: 38px;
}

.ais-ToggleRefinement-checkbox:checked::before {
  content: 'Yes';
}

.ais-ToggleRefinement-count {
  display: none;
}

/* RatingMenu */

.ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
  opacity: 0.5;
}

.ais-RatingMenu-starIcon {
  margin-right: 0.5rem;
}

/* Hide all mobile-specific design on desktop */

@media (min-width: 900px) {
  [data-layout='mobile'] {
    display: none;
  }
}
