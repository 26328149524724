@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;700;900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// Variables
$color-red-1: #db4c3f;
$color-yellowish-1: #b27200de;
$color-white: white;
$color-grey-1: #f1f1f1;
$color-grey-2: #fafafa;
$color-grey-3: #ddd;
$color-grey-4: #ececec;
$color-grey-5: #b2b2b2;
$color-grey-6: grey;
$color-grey-7: #ffffff33;
$color-grey-transparent-1: #00000080;

$color-black-1: #202020;

$font-size-small: 13px;
$font-size-primary: 14px;
$font-size-mlarge: 17px;
$font-size-large: 20px;
$font-size-extra-large: 26px;

$border-radius-small: 3px;
$border-radius-medium: 5px;
$border-radius-large: 10px;


// Content
.content {
  width: 100%;
  max-width: 900px;
  height: 100vh;
  margin: auto;
  padding: 70px 50px;
  overflow: scroll;

  .content__section {
    margin-top: 45px;

    .content__subTitle {
      margin-bottom: 8px;
    }
  }

  .content__placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 15%;

    @media screen and (max-width: 900px) {
      margin-top: 35%;
    }
    @media screen and (max-width: 450px) {
      margin-top: 40%;
    }

    .content__placeholderImg {
      max-width: 250px;
    }

    .content_pMedium {
      font-size: 16px;
      font-weight: 300;
      margin: 16px 0 6px 0;
    }

    .content_pSmall {
      color: $color-grey-6;
      font-size: $font-size-small;
      font-weight: 300;
      margin: 8px 0 24px 0;
    }
  }
}


.header-result {
  display: flex;
  flex-direction: column;
}

.container-header-query {
  font-weight: 300;
  font-size: 16px;

  a {

  }
}

.container-header-suggest {
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
  color: #1a0dabd1;
  cursor: pointer;

}

.loading-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-results-shadow {
  //background-color: #000000;
  //opacity: 0.9;
  //box-shadow: 0 0 69px 26px rgb(0 0 0 / 30%);
  //border-radius: 5px;
  margin-top: 10px;
  padding: 20px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 40%);
  //padding: 1rem;
  position: absolute;
  right: 0;
  margin: 56px 25px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  width: 250px;

  border: 1px solid rgb(176, 125, 1);

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.4rem;
  }

  .card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 13px;
    margin-top: 3px;

    .card-header-text {
      margin-left: 14px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .name {
        font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
      }

      .email {
        font-size: 13px;
        color: gray;
        display: contents;
      }
    }

  }


  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.1rem 0.4rem;
  }

  hr {
    border: none;
    height: 1px;
    background-color: #808080;
    margin: 4px 0;
  }


  .card-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.3rem;
    width: 100%;
    padding-bottom: 3px;
    padding-top: 3px;

    span {
      margin-left: 0.8rem;
      font-size: 15px;
      height: 32px;
      line-height: 31px;
      margin-right: auto;
    }

    .row-icon {
      color: #808080;
    }
  }

  .card-row:hover {
    cursor: pointer;
    border-radius: 4px;
    background: $color-grey-1;
  }
}

.modal-nav__item {
  cursor: pointer;
  padding: 6px;
  margin-bottom: 4px;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $color-black-1;
  width: 95%;

  &:hover {
    background-color: $color-grey-1;
  }

  .logout {
    color: $color-red-1;
  }
}

.modal-nav__icon {
  justify-content: flex-start;
  align-items: center;
  margin-left: 6px;
  min-width: 33px !important;
}


.profile-icon-f {
  //width: auto !important;
  align-items: center;
  justify-content: center;

  .shadow {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);

  }

}

.profile-icon {
  //width: auto !important;
  align-items: center;
  justify-content: center;

  .shadow {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);

  }

}


// create media for card
@media screen and (max-width: 700px) {
  .card {
    //width: 200px;
    //margin: 56px 15px;
  }
  .profile-icon {
    //width: 34px;
  }
}

.page {
  display: flex;
  width: 100%;
}

.account {
  position: absolute;
  top: 40px;
  min-width: 160px;
  overflow: scroll;
  font-size: $font-size-small;
  color: black;
  border: 1px solid $color-grey-4;
  border-radius: $border-radius-small;
  background-color: $color-white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
  rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  .account_row {
    display: flex;
    align-items: center;
    margin: 8px;
    font-size: $font-size-primary;
  }

  .account_userInfo {
    height: 35px;
    font-weight: 400;

    svg {
      color: $color-red-1;
    }
  }

  .account_logout {
    cursor: pointer;
    height: 30px;
    padding: 4px;
    margin: 4px;
    border-radius: $border-radius-small;

    svg {
      color: $color-grey-5;
    }
  }

  .account_logout:hover {
    background-color: $color-grey-1;
  }

  svg {
    margin-right: 12px;
    font-size: 20px;
  }
}


.button__secondary {
  background-color: $color-white;
  border: 1px solid $color-grey-3;
}

.button__secondary:hover {
  background-color: $color-grey-1;
}

.auth {
  display: flex;
  height: 100vh;
  background-color: $color-grey-2;

  h1 {
    font-size: $font-size-extra-large;
    margin-bottom: 30px;
  }

  .auth__dividerText {
    //text-align: center;
    font-size: $font-size-small;
    height: 0.5em;
    color: #777;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 0.5em;
    margin: 30px 0;
  }

  .auth__agreement {
    //text-align: center;
    font-size: $font-size-small;
    //height: 0.5em;
    color: #777;
    //border-bottom: 1px solid #dbdbdb;
    //margin-bottom: 0.5em;
    //margin: 30px 0;
  }

  .auth__dividerText:first-line {
    background-color: white;
  }

  .whiteDashes {
    color: white;
  }

  hr {
    background-color: #dbdbdb;
    margin: 30px 0;
  }

  p {
    margin: 0;
    text-align: center;
    font-size: $font-size-small;
  }

  label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: $font-size-small;
  }

  a {
    color: $color-red-1;
  }

  input {
    height: 35px;
    padding: 10px 13px;
    margin-bottom: 10px;
    border: 1px solid $color-grey-3;
    border-radius: $border-radius-small;
  }

  .auth__brand {
    margin-bottom: 14px;
  }

  .auth__container {
    width: 450px;
    margin: auto;
    padding: 25px;
    border: 1px solid #dbdbdb;
    border-radius: $border-radius-large;
    background-color: white;

    @media screen and (max-width: 450px) {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: none;
    }

    button {
      font-size: $font-size-small;
      margin: 0;
      width: 100%;
      padding: 6px;
      height: 36px;
      border-radius: $border-radius-small;
    }

    .auth__providerBtn {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
      }

      svg {
        margin-right: 10px;
        font-size: 19px;
      }

      .auth__iconGuest {
        color: $color-red-1;
      }
    }

    .auth__providerBtn:first-of-type {
      margin-bottom: 10px;
    }

    .button__primary {
      font-weight: 600;
    }

    .auth__brand {
      display: flex;
      align-items: center;
      color: $color-yellowish-1;

      svg {
        margin-right: 10px;
        font-size: $font-size-extra-large;
      }

      span {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.3px;
      }
    }

    .auth__form {
      display: flex;
      flex-direction: column;
    }
  }

  .auth__error {
    display: flex;
    align-items: center;
    color: $color-red-1;
    font-size: $font-size-primary;
    font-weight: 400;
    margin-bottom: 30px;

    svg {
      font-size: $font-size-large;
      margin-right: 5px;
    }
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 25%;
  max-width: 220px;
  height: 100%;
  background-color: #fafafa;
}

.model-content_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-bottom: 1px solid $color-grey-3;
  width: 100%;

  //.model-content_details__title {
  //  font-size: $font-size-primary;
  //  font-weight: 600;
  //  margin-bottom: 0.5rem;
  //}

  //.model-content_details__subtitle {
  //  font-size: $font-size-small;
  //  color: $color-grey-5;
  //}
}

.MuiListItem-root.Mui-selected {
  background-color: #e6e6e6;
}

.modal-nav {
  margin-bottom: 2rem;

  .modal-nav__title {
    font-size: $font-size-mlarge;
    font-weight: 600;
  }

  .modal-nav__close {
    cursor: pointer;
    font-size: $font-size-large;
    color: $color-grey-5;
  }
}
